import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { GET_INVENTORY_QUERY, GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { JSONParseSafely, NumberFormat, PriceFormat } from '../../../shared';
import EllipsisTypography from '../../../components/EllipsisTypography';
import ConnectionCardHeader from '../../../components/ConnectionPageMaker/ConnectionCardHeader';
import { Divider, Grid, Typography } from '@material-ui/core';
import { theme } from '../../../theme';
import ExportButton from '../ExportButton';
import FormHeader from '../FormHeader';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import LSkeleton from '../../../components/LSkeleton';
import { isNil } from 'lodash';

class ProductAnalyst extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_products_data,
    stickyHeader: true,
    getRowLink: ({ sku, name }) => ({
      pathname: `/sale_performance_and_data/product_sales/${sku}`,
      state: { title: name },
    }),
    fields: [
      {
        label: translate.product,
        title: () => (
          <Typography variant={'h6'} color={'secondary'}>
            {translate.all_products}
          </Typography>
        ),
        render: (row) => (
          <>
            <EllipsisTypography>{row.name}</EllipsisTypography>
            <EllipsisTypography>{row.sku}</EllipsisTypography>
          </>
        ),
      },
      {
        label: translate.sold_count,
        title: () => {
          const { totalCount } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalCount)}
            </Typography>
          );
        },
        fieldName: 'count',
        sortBy: 'count',
        type: 'number',
        align: 'right',
        width: 120,
      },
      {
        label: translate.orders,
        title: () => {
          const { totalOrder } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalOrder)}
            </Typography>
          );
        },
        fieldName: 'orderCount',
        type: 'number',
        sortBy: 'orderCount',

        align: 'right',
        width: 100,
      },
      {
        label: translate.sale_amount,
        title: () => {
          const { totalAmount } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(totalAmount)}
            </Typography>
          );
        },
        fieldName: 'amount',
        type: 'price',
        sortBy: 'amount',
        align: 'right',
        width: 100,
      },
      {
        label: translate.average_price,
        title: () => {
          const { totalAvgPrice } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(totalAvgPrice)}
            </Typography>
          );
        },
        fieldName: 'avgPrice',
        type: 'price',
        align: 'right',
        width: 100,
      },
      {
        label: translate.average_sold_count,
        title: () => {
          const { totalAvgCount } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalAvgCount)}
            </Typography>
          );
        },
        fieldName: 'avgCount',
        type: 'number',
        align: 'right',
        width: 130,
      },
      {
        label: translate.oos || '售罄率',
        render: (row) => {
          if (row?.loadingQuantity) return <LSkeleton />;
          const value = (() => {
            const soldCount = row?.count ?? 0;
            if (!soldCount || !row?.quantity || row?.quantity <= 0) return undefined;
            const salePerDay = soldCount / (row?.numberOfDays || 1);
            return Math.ceil(row?.quantity / salePerDay);
          })();
          return (
            <EllipsisTypography
              color={value > 0 ? undefined : value < 0 ? 'error' : 'textSecondary'}
              title={
                <>
                  {translate.stock} {row?.quantity}
                  <br />
                  {translate.average_daily_sale || '每日平均銷售'} {(row?.count / row?.numberOfDays).toFixed(2)}
                </>
              }
            >
              {isNil(value) ? '-' : `${NumberFormat(value)} ${translate.day}`}
            </EllipsisTypography>
          );
        },
        align: 'right',
        width: 120,
      },
      {
        label: translate.page_views,
        title: () => {
          const { totalPageView } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalPageView)}
            </Typography>
          );
        },
        fieldName: 'pageView',
        type: 'number',
        sortBy: 'pageView',

        align: 'right',
        width: 100,
      },
      {
        label: translate.click_rate,
        title: () => {
          const { totalHitRate } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalHitRate)}
            </Typography>
          );
        },
        fieldName: 'hitRate',
        sortBy: 'hitRate',

        type: 'number',
        align: 'right',
        width: 100,
      },
      {
        label: translate.conversion_rate,
        title: () => {
          const { totalConversionRate } = this.totalProductSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalConversionRate)}%
            </Typography>
          );
        },
        value: (row) => `${NumberFormat(row.conversionRate) * 100}%`,
        align: 'right',
        width: 100,
      },
    ],
    hasQSearch: true,
    qFields: ['name', 'sku'],
  };

  getSortBy() {
    const { sortBy } = this.state;
    return sortBy ? [sortBy] : [];
  }

  onCompleted(data) {
    const { node } = data || {},
      { report } = node || {},
      { totalProductSales } = report || {};
    this.totalProductSales = totalProductSales;
    this.setState({ ...this.state });
  }

  getExtraFetchVariables() {
    const { startedAt, startedThru, shop } = this.getQueryParams();
    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      shopId: JSONParseSafely(shop)?.id,
    };
  }

  getSkip() {
    const { startedAt, startedThru } = this.getQueryParams();
    if (!startedAt || !startedThru) return true;
    return super.getSkip();
  }

  getQuery() {
    return GET_QUERY;
  }

  getData({ node } = {}) {
    const { report } = node || {},
      { topProductSales } = report || {};
    return topProductSales;
  }

  renderTable(connection) {
    const { startedAt, startedThru, shop } = this.getQueryParams();

    const numberOfDays = Math.max(
      Math.abs(Math.floor((new Date(startedThru) - new Date(startedAt)) / (1000 * 60 * 60 * 24))) + 1,
      1,
    );
    const skus = connection?.nodes?.map((node) => node?.sku)?.filter(Boolean) ?? [];
    return (
      <>
        <Divider style={{ height: 2, backgroundColor: '#999' }} />
        {skus?.length > 0 ? (
          <Query
            query={GET_INVENTORY_QUERY(skus)}
            variables={{
              shopId: JSONParseSafely(shop)?.id,
              companyId: localStorage.getItem('companyId'),
            }}
          >
            {({ loading, data }) => {
              return super.renderTable({
                ...connection,
                nodes:
                  connection?.nodes?.map((node) => {
                    const index = skus.indexOf(node?.sku);
                    const quantity = data?.[`sku_${index}`] ?? 0;
                    return {
                      ...node,
                      quantity,
                      numberOfDays,
                      loadingQuantity: loading,
                    };
                  }) ?? [],
              });
            }}
          </Query>
        ) : (
          super.renderTable(connection)
        )}
      </>
    );
  }

  renderCardHeader(context) {
    return (
      <>
        <ConnectionCardHeader style={{ paddingBottom: 0, marginBottom: -theme.spacing(1) }}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Typography variant={'h6'} color={'secondary'}>
                {translate.all_products_analysis}
              </Typography>
            </Grid>
            <Grid item>
              <ExportButton
                mutation={gql`
                  mutation ($exportShopIds: [ID!], $shopId: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
                    productsReportExport(
                      exportShopIds: $exportShopIds
                      shopId: $shopId
                      startedAt: $startedAt
                      startedThru: $startedThru
                    ) {
                      id
                      updatedAt
                      createdAt
                      status
                      output
                    }
                  }
                `}
              />
            </Grid>
          </Grid>
        </ConnectionCardHeader>
        {super.renderCardHeader(context)}
      </>
    );
  }
  renderExtras() {
    return <FormHeader />;
  }
}

export default ProductAnalyst;
