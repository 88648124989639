import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import RemarkCard from './RemarkCard';
import CombinationField from './CombinationField';
import MultiVariantCard from './MultiVariantCard';
import { GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import {
  parseConnection,
  removeTypename,
  cloneObject,
  convertMetaArray,
  convertMetaObject,
  isProduction,
} from 'shared';
import { Box, Card, Grid, InputAdornment, Typography } from '@material-ui/core';
import moment from 'moment';
import StockField from 'routes/item_Product/StockField';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { Query } from '@apollo/client/react/components';
import { GET_QUERY as GET_SHOP } from '../Homepage/query';
import CollectionsField from './CollectionsField';
import SeoCardSetting from './SeoCardSetting';
import ean13Fix from './ean13Fix';
import ShippingZoneCard from './ShippingZoneCard';
import AllShopSelector from './AllShopSelector';
import Taber from '../../components/Taber';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import { combinationsFix } from './combinationsFix';
import { JSONParseSafely, toInputMedia } from '../../shared';
import { hasCombinations } from './hasCombinations';
import { fixVariationOrder } from './fixVariationOrder';
import CopyButton from '../../components/CopyButton';
import ActivityIndicator from '../../components/ActivityIndicator';
import confirmation from '../../shared/confirmation';
import DescriptionCard from './DescriptionCard';
import CustomFormField from '../../components/CustomFormField';
import StockMovementsCard from './StockMovementsCard';
import AttributesField from './AttributesField';
import MagicImagesCard from './MagicImagesCard';
import MagicMediasField from '../../components/FormPageMaker/Fields/MagicMediasField';
import SalesChannelField from './SalesChannelField';
import { Skeleton } from '@material-ui/lab';
import TooltipContent from '../../components/TooltipContent';
import { getSubmitVariations } from './getSubmitVariations';
import SwitchGreyField from '../../components/FormPageMaker/Fields/SwitchGreyField';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';
import LSkeleton from '../../components/LSkeleton';
import QuickGenerateButton from './QuickGenerateButton';
import FieldSettingField from '../../components/CustomFormField/FieldSettingField';
import KeetaCard from './KeetaCard';
import PriceRecordsCard from './PriceRecordsCard';
import { convertKeetaInput2Setting, convertKeetaSetting2Input } from './keetaSetting';

export const inventoryUnits = [
  { label: translate.units, value: 'units' },
  { label: translate.pairs, value: 'pairs' },
  { label: translate.sets, value: 'sets' },
  { label: translate.items_unit, value: 'items' },
  { label: translate.pieces, value: 'pieces' },
  { label: translate.slices, value: 'slices' },
  { label: translate.grains, value: 'grains' },
];
export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.product_settings,
        cards: [
          {
            fields: [
              {
                label: translate.product_name,
                type: 'text',
                maxLength: 100,
                name: 'name',
                required: true,
                md: 6,
              },
              {
                label: translate.subtitle,
                tooltip: (
                  <TooltipContent
                    src={require('../../assets/product_subtitle_preview.png')}
                    points={[<Typography variant="body2">{translate.product_subtitle_remark}</Typography>]}
                  />
                ),
                type: 'text',
                maxLength: 50,
                name: 'subtitle',
                md: 6,
              },
              {
                label: translate.menu_product_collections || '產品分類',
                render: (props) => <CollectionsField {...props} />,
              },
              {
                label: translate.product_sku,
                name: 'sku',
                type: 'text',
                required: true,
                md: 6,
                maxLength: 50,
                placeholder: translate.sku_placeholder_example || '例如：ABC1234',
                description: translate.sku_placeholder || '產品編號的數值不可與其他產品編號重複，僅允許字母和數字',
                render: ({ values: { sku } = {}, setFieldValue, loading, disabled }) => {
                  return (
                    <InputField
                      loading={loading}
                      disabled={disabled}
                      required
                      type="text"
                      value={sku}
                      maxLength={50}
                      onChange={(e) => {
                        setFieldValue('sku', e.target.value.replace(/\s+/g, '_'));
                      }}
                      onBlur={(e) => {
                        if (e.target.value === sku) return;
                        setFieldValue('sku', e.target.value.replace(/\s+/g, '_'));
                      }}
                    />
                  );
                },
              },
              {
                label: translate.barcode,
                name: 'barcodes',
                isOneRow: true,
                hideHash: true,
                type: 'hashTagsArray',
                md: 6,
                maxLength: 50,
                // afterChanged: (v, { values: { combinations }, setFieldValue }) => {
                //   if (!hasCombinations(combinations)) {
                //     setFieldValue('variations[0].barcodes', !!v?.length ? v : []);
                //   }
                // },
                remarks: ({ loading, setFieldValue, values: { barcodes } }) => {
                  return (
                    <QuickGenerateButton
                      loading={loading}
                      onChange={(e) => setFieldValue('barcodes', [e, ...barcodes])}
                    />
                  );
                },
              },
              {
                label: translate.price,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                name: 'variations[0].unitPrice',
                type: 'number',
                required: true,
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.suggested_retail_price,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                name: 'variations[0].suggestedRetailPrice',
                type: 'number',
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.cost,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                name: 'variations[0].cost',
                type: 'number',
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                md: 3,
                type: 'text',
                label: translate.average_cost,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                name: 'variations[0].averageCost',
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
                disabled: true,
              },
              {
                md: 12,
                type: 'span',
              },
              {
                md: 12,
                label: translate.low_inventory_alert,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                tooltip: (
                  <TooltipContent
                    points={[<Typography variant="body2">{translate.low_inventory_alert_tips}</Typography>]}
                  />
                ),
                render: ({ values: { variations }, setFieldValue, disabled, loading }) => {
                  const remind = !!variations?.[0]?.lowStock?.remind;
                  const quantity = variations?.[0]?.lowStock?.quantity;
                  if (loading) return <LSkeleton height={30} />;
                  return (
                    <Grid container spacing={1}>
                      <Grid item>
                        <SwitchField
                          disabled={disabled}
                          checked={remind}
                          onChange={() => setFieldValue('variations[0].lowStock.remind', !remind)}
                        />
                      </Grid>
                      {remind && (
                        <Grid item xs={true}>
                          <InputField
                            disabled={disabled}
                            type={'number'}
                            value={quantity}
                            onChange={(e) => setFieldValue('variations[0].lowStock.quantity', e?.target?.value)}
                            inputProps={{
                              step: 1,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {translate.alert_quantity || '提醒數量'}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              },
              !isProduction() && {
                md: 6,
                block: true,
                render: ({ values: { metadata = {} } = {}, loading, disabled, isSubmitting, setFieldValue }) => {
                  const { contactForPricing } = metadata || {};
                  return (
                    <SwitchGreyField
                      label={translate.contact_for_pricing || '價格面議（網店專用）'}
                      checked={contactForPricing}
                      onChange={(e) => {
                        setFieldValue('metadata', {
                          ...metadata,
                          contactForPricing: e.target.checked,
                        });
                      }}
                      loading={loading}
                      disabled={isSubmitting || disabled}
                      tooltip={
                        <TooltipContent
                          points={[
                            <Typography variant="body2">
                              {translate.contact_for_pricing_tips ||
                                '啟用後，客戶將無法直接在網站上購買產品，而需填寫聯絡表格，以獲取個性化報價'}
                            </Typography>,
                          ]}
                        />
                      }
                    />
                  );
                },
              },
              {
                md: 3,
                label: translate.activation,
                type: 'switch',
                name: 'active',
              },
              {
                md: 3,
                label: translate.visible,
                type: 'switch',
                name: 'display',
              },
              {
                label: translate.sales_channel || '銷售渠道',
                render: ({ loading, disabled, values, setFieldValue }) =>
                  loading ? (
                    <Skeleton height={30} width={100} />
                  ) : (
                    <SalesChannelField
                      disabled={disabled}
                      value={values.salesChannels}
                      onChange={(v) => setFieldValue('salesChannels', v)}
                    />
                  ),
                md: '3',
              },
              { md: 3 },
              {
                label: translate.stock,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                render: ({ values: { variations } = {}, loading, isSubmitting, setFieldValue }) => {
                  const variation = (variations || [])[0] || {};

                  return (
                    <StockField
                      loading={loading}
                      disabled={isSubmitting}
                      value={{
                        quantity: variation.quantity,
                        ignoreStock: variation.ignoreStock,
                      }}
                      onChange={(update) => {
                        setFieldValue('variations', [
                          {
                            ...variation,
                            ...update,
                          },
                        ]);
                      }}
                    />
                  );
                },
                required: true,
                md: 6,
              },
              {
                label: translate.inventory_unit,
                name: 'variations[0].unit',
                type: 'select',
                options: inventoryUnits,
                md: 6,
              },
              {
                label: translate.weight,
                display: ({ values: { combinations = [] } = {} }) => !hasCombinations(combinations),
                name: 'variations[0].weight',
                type: 'number',
                md: 6,
                inputProps: {
                  min: 0,
                  step: 0.001,
                },
                InputProps: {
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                },
              },
              { md: 6 },
              {
                label: translate.online_day_time,
                name: 'publishAt',
                type: 'datetime-local',
                md: 6,
                text: translate.start_time_remark,
              },
              {
                label: translate.offline_day_time,
                description: translate.available_if_no_off_shelf_date || '如不輸入下架日期，系統將視為常設產品',
                name: 'publishThru',
                type: 'datetime-local',
                md: 6,
                text: translate.end_time_remark,
                hasPermanent: true,
              },
              {
                label: translate.hash_tag,
                description: translate.hash_tag_description || '系統會自動在輸入內容前加上 "#" 符號，請直接輸入內容',
                name: 'hashtags',
                type: 'hashTagsArray',
                md: 12,
                placeholder:
                  translate.remark_hashtags_placeholder || 'e.g. 熱門優惠　新品上市　Hot Deals　New Arrivals',
              },
              {
                label: translate.sort_index,
                tooltip: (
                  <TooltipContent
                    src={require('../../assets/display_order.png')}
                    points={[
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {translate.display_order_remark || '系統以升序排列，數字越小，產品會越靠左顯示。'}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item>
                              <Typography variant="body2">{translate.for_example}：</Typography>
                            </Grid>
                            <Grid item>
                              {translate.product_sort_index_help_remark?.split('\n').map((str, i) => (
                                <Typography key={i} variant="body2">
                                  {str}
                                </Typography>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>,
                    ]}
                  />
                ),
                type: 'number',
                inputProps: {
                  step: 1,
                },
                name: 'primarySortIndex',
                md: 6,
                text: translate.sort_index_remark,
              },
              {
                md: 6,
                label: translate.shelf_tag || '貨架位置',
                name: 'variations[0].stockLocations',
                isOneRow: true,
                hideHash: true,
                type: 'hashTagsArray',
                display: ({ values: { combinations = [] } = {} }) =>
                  !hasCombinations(combinations) && !(combinations.length === 1 && combinations[0].options.length >= 0),
              },

              {
                label: translate.image,
                tooltip: (
                  <TooltipContent
                    src={require('../../assets/product_preview.png')}
                    points={[
                      <Typography variant="body2">
                        {translate.product_image_remark || '建議產品圖片尺寸為 1000px : 1000px ( 1:1 比例)'}
                      </Typography>,
                    ]}
                  />
                ),
                render: ({ values: { medias } = {}, loading, isSubmitting, setFieldValue }) => (
                  <MagicMediasField
                    loading={loading}
                    value={medias}
                    onChange={(v) => setFieldValue('medias', v)}
                    disabled={isSubmitting}
                  />
                ),
              },
              {
                label: translate.product_variants,
                tooltip: (
                  <TooltipContent
                    points={[
                      <Typography variant="body2">
                        {translate.product_variants_remarks || '您可以為產品建立不同規格，如尺寸、顏色、款式等'}
                      </Typography>,
                    ]}
                  />
                ),
                render: (actions) => <CombinationField {...actions} />,
              },
              {
                render: (actions) => <MultiVariantCard {...actions} />,
              },
            ],
          },
        ],
      },
      {
        name: translate.image,
        cards: [MagicImagesCard],
      },
      {
        name: translate.product_descriptions,
        cards: [DescriptionCard],
      },
      {
        name: translate.customize_table,
        cards: [
          {
            fields: [
              {
                label: `${translate.modifier_remarks} (${translate.just_for_pos})`,
                render: (actions) => (
                  <>
                    <small>{translate.new_variant_remark}</small>
                    <RemarkCard {...actions} />
                  </>
                ),
              },
              {
                label: translate.product_attribute || '產品參數（可用於搜尋產品）',
                render: ({ disabled, values, setFieldValue }) => (
                  <AttributesField
                    disabled={disabled}
                    value={values.attributes}
                    onChange={(v) => setFieldValue('attributes', v)}
                  />
                ),
              },
              {
                label: `${translate.product_remarks}`,
                render: ({ disabled, values, setFieldValue }) => {
                  const preMetadataFields = (
                    values?.modifierValues?.map((modifierValue) => modifierValue.metadataFields)?.flat() ?? []
                  ).filter(Boolean);
                  return (
                    <CustomFormField
                      onlyAllowFieldTypes={['TEXT']}
                      useFieldKey={false}
                      disabled={disabled}
                      value={values?.metadataFields}
                      onChange={(v) => {
                        setFieldValue('metadataFields', v);
                      }}
                      header={
                        <Grid container spacing={3}>
                          {preMetadataFields?.map((metadataField, i) => (
                            <Grid key={`mt-${i}`} item xs={12}>
                              <Card>
                                <Grid container>
                                  <Grid item>
                                    <Box
                                      height={'100%'}
                                      width={'50.5px'}
                                      display={'flex'}
                                      alignItems={'center'}
                                      p={2}
                                      style={{
                                        borderRight: `1px solid #e1e1e1`,
                                      }}
                                    ></Box>
                                  </Grid>
                                  <Grid item xs>
                                    <FieldSettingField
                                      onlyAllowFieldTypes={['TEXT']}
                                      value={metadataField}
                                      disabled={true}
                                    />
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      }
                    />
                  );
                },
              },
              {
                label: `${translate.custom_field || '自定義欄位'}1`,
                type: 'textarea',
                name: 'metadata.customField1',
              },
              {
                label: `${translate.custom_field || '自定義欄位'}2`,
                type: 'textarea',
                name: 'metadata.customField2',
              },
              {
                label: `${translate.custom_field || '自定義欄位'}3`,
                type: 'textarea',
                name: 'metadata.customField3',
              },
            ],
          },
        ],
      },
      {
        name: translate.inventory_record,
        noPadding: true,
        cards: [
          {
            fields: [StockMovementsCard],
          },
        ],
      },
      {
        name: translate.price_history || '售價記錄',
        noPadding: true,
        cards: [
          {
            fields: [PriceRecordsCard],
          },
        ],
      },
      !isProduction() && {
        name: translate.delivery_method,
        noPadding: true,
        cards: [
          {
            fields: [ShippingZoneCard],
          },
        ],
      },
      SeoCardSetting,
      {
        name: translate.keeta_settings || 'Keeta設定',
        cards: [KeetaCard],
      },
    ].filter((_) => _),
    allShop: !this.state.id,
    shopId: localStorage.getItem('shopId'),
    sku: '',
  };

  submit = async (values) => {
    const {
      id,
      name,
      sku,
      barcodes = [],
      description,
      collections = [],
      active,
      display,
      medias,
      publishAt,
      publishThru,
      modifiers,
      salesChannels,
      variations,
      combinations = [],
      primarySortIndex,
      hashtags,
      subtitle,
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      details = [],
      printDescription,
      shippingZones = [],
      metadataFields = [],
      metadata,
      attributes = [],
      settingKeeta,
    } = values;
    const {
      action,
      gql: { submit },
      shopId,
      allShop,
    } = this.state;

    const _unit = variations?.[0]?.unit || inventoryUnits[0].value;
    const _variations = variations.map((variation, i) => {
      return this.initialValues.variations[i]?.quantity &&
        variation?.quantity === this.initialValues.variations[i]?.quantity
        ? { ...variation, quantity: undefined, unit: _unit }
        : { ...variation, unit: _unit };
    });

    const {
      data: { result },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id: action === 'update' ? id : undefined,
        input: {
          name,
          shopId,
          sku: sku,
          barcodes: barcodes.map(ean13Fix),
          collectionIds: collections.map((col) => col.id).filter((_) => _),
          metadataFields,
          salesChannels,
          metadata: convertMetaArray(metadata),
          variations: getSubmitVariations({
            sku,
            active,
            display,
            variations: action === 'update' ? _variations : variations,
            combinations,
            barcodes,
          }),
          active,
          display,
          medias: medias.map(toInputMedia),
          publishAt: moment(publishAt).toISOString(),
          publishThru: !!publishThru ? moment(publishThru).toISOString() : null,
          modifiers:
            modifiers?.map((modifier) => ({
              name: modifier?.name || '',
              max: modifier?.max || undefined,
              min: modifier?.min || undefined,
              options:
                modifier?.options.map((opt) => ({
                  name: opt?.name || '',
                  priceAdjustment: opt?.priceAdjustment || 0,
                })) ?? [],
            })) ?? [],
          hashtags,
          subtitle,
          combinations,
          basePrice: (_.minBy(variations, 'unitPrice') || {}).unitPrice || 0,
          maxPrice: (_.maxBy(variations, 'unitPrice') || {}).unitPrice || 0,
          primarySortIndex,
          metaTitle,
          metaDescription,
          metaKeywords,
          rewriteUri: rewriteUri || null,
          shippingZoneIds: shippingZones.map((zone) => (zone || {}).id),
          description,
          details: details.map((detail) => ({
            title: (detail || {}).title || '',
            content: (detail || {}).content || '',
          })),
          printDescription,
          attributes:
            attributes
              ?.map(
                (attr) =>
                  !!attr?.key &&
                  !!attr?.value && {
                    id: attr?.id,
                    key: attr?.key,
                    value: attr?.value,
                  },
              )
              ?.filter((_) => _) ?? [],
          settingKeeta: convertKeetaSetting2Input(settingKeeta, values),
        },
        allShop,
        warehouseId: localStorage.getItem('stockWarehouseId'),
      },
    });
    const { submittedBehavior } = this.state;
    if (submittedBehavior === 'id') return result.id;
    return true;
  };

  remove = async ({ values }) => {
    const {
      id,
      gql: { remove },
    } = this.state;

    if (!!values?.bundleProducts?.nodes?.length) {
      ActivityIndicator.hide();
      return await confirmation(
        translate.product_with_bundleProduct_delete_message +
          '\n' +
          values?.bundleProducts?.nodes?.map(({ name, sku }) => name + ` (${sku})`)?.join('\n'),
        null,
        null,
      );
    }

    return await client.mutate({
      mutation: remove,
      variables: { id },
    });
  };

  getExtraFetchVariables() {
    return { warehouseId: localStorage.getItem('stockWarehouseId') };
  }

  getInitialData(data) {
    const { copyValues } = this.state || {};
    const { node } = data || {},
      {
        id,
        name = '',
        barcodes = [],
        sku = '',
        description = '',
        medias = [],
        active = true,
        display = true,
        modifiers,
        salesChannels,
        combinations: _combinations,
        basePrice = 0,
        primarySortIndex = 100,
        variations: _variations,
        updatedAt,
        publishAt,
        publishThru,
        subtitle = '',
        hashtags = [],
        metaTitle = '',
        metaDescription = '',
        metaKeywords = '',
        rewriteUri = '',
        details,
        shippingZones,
        bundleProducts,
        metadataFields,
        metadata,
        attributes,
        printDescription,
        modifierValues,
        settingKeeta,
      } = node || {};
    const { nodes: collections } = parseConnection((node || {}).collections);
    const variations = cloneObject(_variations || []).map((variation) => {
      return {
        ...variation,
        stockLocations:
          variation.stockLocations !== null && !!variation.stockLocations.length ? variation.stockLocations : [],
        // 原本用barcode, 現時容許輸入多個barcode, 所以將舊有barcode push進barcodes[],submit時barcode = ""
        barcodes: _.uniq([variation.barcode, ...variation.barcodes].filter((_) => _)),
      };
    });
    const combinations = combinationsFix(_combinations, variations);

    const fixVariations = fixVariationOrder(
      removeTypename(cloneObject(variations || [{ cost: 0, active: true, unit: inventoryUnits[0].value }])),
      combinations,
    );

    return {
      id,
      name,
      collections,
      barcodes: barcodes.filter((_) => _),
      sku,
      active,
      display: display ?? true,
      description,
      salesChannels: _.uniq(['DEFAULT'].concat(salesChannels || ['POS', 'CUTSOMERADO'])),
      publishAt,
      publishThru,
      basePrice,
      primarySortIndex,
      metadata: convertMetaObject(metadata),
      metadataFields: removeTypename(cloneObject(metadataFields || [])),
      details: removeTypename(cloneObject(details || [])),
      printDescription,
      modifiers: removeTypename(cloneObject(modifiers || [])),
      combinations,
      variations: !!variations?.length ? fixVariations : [{ unit: inventoryUnits[0].value }],
      medias: cloneObject(medias || []),
      hashtags: cloneObject(hashtags || []),
      subtitle,
      updatedAt,
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      shippingZones: cloneObject(shippingZones || []),
      bundleProducts,
      attributes: attributes || [],
      modifierValues,
      settingKeeta: convertKeetaInput2Setting(settingKeeta),
      ...copyValues,
    };
  }

  onCompleted(data) {
    const { history } = this.props;
    const { action } = this.state;
    const companyId = data?.node?.shop?.company?.id;

    if (action === 'update' && (!data?.node || companyId !== localStorage.getItem('companyId'))) {
      toast.error(errorParser(new Error('Invalid Product')));
      history.replace('/products');
    } else {
      this.setState({ sku: data?.node?.sku });
    }
    return super.onCompleted(data);
  }

  getFetchResult(result) {
    const { action } = this.state;
    const { loading, data } = result || {};
    if (action === 'update') return { loading: loading && !data, data };
    return { loading, data };
  }

  renderContent(result) {
    const { className } = this.props,
      { id } = this.state;
    const { loading, data } = this.getFetchResult(result);

    this.initialValues = this.getInitialData(data || {});

    return (
      <div key={id || 'new'} className={className}>
        {this.renderForm({ ...this.initialValues }, loading)}
      </div>
    );
  }

  renderTaber() {
    const { tabs, allShop, shopId, id, sku } = this.state;

    return (
      <Taber
        tabs={tabs}
        currentTab={this.getActiveTab()}
        onTabChange={(currentTab) =>
          this.setState({
            currentTab,
          })
        }
        extra={
          <AllShopSelector
            sku={sku}
            allShop={allShop}
            hasAllOption={!id}
            value={shopId}
            onChange={(v, productId) => {
              if (v === 'all') this.setState({ shopId: localStorage.getItem('shopId'), allShop: true });
              else this.setState({ shopId: v, allShop: false });

              if (productId) this.setState({ id: productId, action: 'update' });
              else this.setState({ action: 'create' });
            }}
          />
        }
      />
    );
  }

  renderExtraButtons({ loading, values }) {
    const shopId = localStorage.getItem('shopId'),
      { id } = this.state;
    if (!id) return null;
    return (
      <Query
        query={GET_SHOP}
        variables={{
          shopId,
        }}
        skip={!shopId}
      >
        {({ data: { shop } = {} }) => {
          const { hostname, customDomain } = shop || {};
          if (!hostname && !customDomain) return null;
          return (
            <Grid container style={{ placeContent: 'flex-end' }} spacing={1}>
              <Grid item>
                <a href={`//${customDomain || hostname}/product/${id}`} target={'_blank'} rel="noopener noreferrer">
                  <Button disabled={loading} variant={'contained'} color={'primary'}>
                    {translate.preview}
                  </Button>
                </a>
              </Grid>
              <Grid item>
                <CopyButton
                  values={values}
                  getCopyValues={() => {
                    const clone = cloneObject(values);
                    return {
                      ...clone,
                      rewriteUri: undefined,
                      id: undefined,
                      sku: undefined,
                      attributes: clone.attributes.map((attr) => {
                        return {
                          ...attr,
                          id: undefined,
                        };
                      }),
                      variations: clone.variations.map((variation) => {
                        return {
                          ...variation,
                          id: undefined,
                          sku: undefined,
                        };
                      }),
                    };
                  }}
                  disabled={loading}
                  url={'/products/new'}
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    );
  }
}
