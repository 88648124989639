import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../../item_EmailTemplate/SplitCard';
import { defaultSuggestions } from '../defaultSuggestion';
import LayoutFront from '../LayoutFront';
import { LV } from '../../../components/ReceiptTemplatePreviewer';
import { PriceFormat } from '../../../shared';

export default class LayoutReturn extends LayoutFront {
  type = 'RETURN';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={[
                { label: '#index', value: '{{index}}' },
                { label: '#count', value: '{{count}}' },
                { label: '#subtitle', value: '{{subtitle}}' },
                { label: '#description', value: '{{description}}' },
                { label: '#returnReferenceNo', value: '{{returnReferenceNo}}' },
                ...defaultSuggestions,
              ]}
              allows={['isTitle', 'isText', 'isLV', 'isDivider', 'isLogo', 'isQRCode', 'isCut']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              standardWidth={300}
              extraData={{
                returnReferenceNo: 'R00000000001',
                remark: '外殼有瑕疵，換一部全新給客人',
                items: [
                  {
                    index: 0,
                    description: 'iPhone 16 Pro (White Titanium / 128GB)',
                    sku: 'IPhone-90000',
                    quantity: 1,
                    unitPrice: 8599,
                    remark: '',
                    total: 8599,
                    reason: '外殼有瑕疵',
                  },
                  {
                    index: 1,
                    description: '240W USB-C Charge Cable (2m)',
                    sku: 'SKU00000088',
                    quantity: 1,
                    unitPrice: 229,
                    remark: '',
                    total: 229,
                    reason: '接觸不良',
                  },
                ],
              }}
              extraRenderer={{
                isItems: ({ items }, config) => {
                  const size = config?.size;
                  return items.map((item, i) => (
                    <div key={i}>
                      <LV label={item?.description} size={size} value={PriceFormat(item?.unitPrice * item?.quantity)} />
                      <div style={{ fontSize: { 1: '1em', 2: '1.5rem', 3: '2rem' }[size] || '1em' }}>
                        {item?.remark}
                      </div>
                    </div>
                  ));
                },
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
